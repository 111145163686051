





















import {Component, Vue} from "vue-property-decorator";
import DownloadList from "./DownloadList.vue";
import axios from "axios";

@Component({
  components: {
    DownloadList,
  },
})
export default class SoftwareDownloads extends Vue {
  public downloads: any[] = [];

  public created() {
    axios.get("/downloads/software")
      .then((result) => {
        this.downloads = result.data;
      });
  }
}
